<template>
  <div
    class="p-2"
    :class="dataProduct.status != 1 ? 'content-product-aviable' : ''"
  >
    <div class="container-card-product-detail">
      <div
        v-if="dataProduct.producto_aplica_estrategia == 1"
        class="product-aplica-estrategia"
      >
        <svg
          id="Shopicons_Light_Gift"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="Trazado_5027"
            data-name="Trazado 5027"
            d="M0,0H24V24H0Z"
            fill="none"
          />
          <g id="Shopicon" transform="translate(1.983 2.184)">
            <path
              id="Trazado_5028"
              data-name="Trazado 5028"
              d="M21.965,8.368H19.791a3,3,0,1,0-5.651,0h-.349a3,3,0,1,0-5.651,0H5.965a2.006,2.006,0,0,0-2,2v12a2.006,2.006,0,0,0,2,2h16a2.006,2.006,0,0,0,2-2v-12A2.006,2.006,0,0,0,21.965,8.368Zm-5-3a2,2,0,1,1-2,2A2,2,0,0,1,16.965,5.368Zm-6,0a2,2,0,1,1-2,2A2,2,0,0,1,10.965,5.368Zm-6,5a1,1,0,0,1,1-1H8.734a2.99,2.99,0,0,0,4.463,0h.061l-4,4,.707.707,3.5-3.5v5.793h-8.5v-6Zm0,12v-5h8.5v6h-7.5A1,1,0,0,1,4.965,22.368Zm18,0a1,1,0,0,1-1,1h-7.5v-6h8.5v-1h-8.5V10.575l3.5,3.5.707-.707-4-4h.061a2.99,2.99,0,0,0,4.463,0h2.768a1,1,0,0,1,1,1Z"
              transform="translate(-3.965 -4.368)"
            />
          </g>
        </svg>
      </div>
      <img
        class="mb-auto"
        :src="dataProduct.referencia.imagenes[0]"
        :alt="
          dataProduct.producto_titulo
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase())
        "
      />
      <div class="container-data-product-card">
        <p class="title-product-card two-wrap-text">
          {{
            dataProduct.producto_titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </p>
        <span class="data-product-card"
          >SKU: {{ dataProduct.producto_sku }} - Color:
          {{ dataProduct.referencia.color_nombre }} - Talla:
          {{ dataProduct.referencia.talla.name }}</span
        >
        <p class="card-product-price">
          <b
            >${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
            {{ parseInt(dataProduct.precio_antes) > 0 ? '-' : '' }}</b
          ><del v-if="parseInt(dataProduct.precio_antes) > 0"
            ><b
              >${{
                new Intl.NumberFormat('de-DE').format(dataProduct.precio_antes)
              }}</b
            ></del
          >
        </p>
      </div>
    </div>
    <p v-if="dataProduct.status != 1" class="mb-0 text-center">
      <span class="w-100 product-aviable fw-bold"
        >Este producto está agotado</span
      >
    </p>
  </div>
</template>
<script>
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
</script>
