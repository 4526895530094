<template>
  <div class="container-method-payment-cupo">
    <div v-if="stepCupo == ''">
      <p
        style="max-width: 660px; margin-left: auto;
        margin-right: auto; margin-bottom: 1.5rem;"
      >
        <b>
          Accede al cupo personal Quac: Rápido, fácil y para todos.
        </b>
      </p>
      <p style="max-width: 660px; margin-left: auto; margin-right: auto">
        ¿Sin historial crediticio? ¡No hay problema! En Quac no necesitas un
        pasado crediticio para empezar a escribir tu historia con nosotros.
      </p>
      <div
        style="max-width: 630px;"
        class="mx-auto alert alert-clover-default p-3 my-5"
      >
        <p class="p-0 m-0">
          Financiamos esta compra,
          <strong>
            págala en hasta 10 cuotas quincenales.
          </strong>
        </p>
      </div>
      <p class="mb-4">
        Ingresa el número de documento y haz clic en el botón continuar.
      </p>
      <div class="container-input-group-bono mb-3">
        <div class="input-simple-clover">
          <label class="label-input" style="top: -14px; z-index: 1"
            >Cédula de ciudadanía</label
          >
          <input
            v-model="dataCart.cliente_documento"
            type="text"
            style="opacity: 0.6"
            disabled
            placeholder="Cédula de ciudadanía"
            @blur="validateBlur('docCupo')"
          />
        </div>
        <div class="container-btn-group">
          <button class="btn-add-cod bnt-primary" @click="validateCupoQuac()">
            Continuar
          </button>
        </div>
      </div>
      <div v-if="errorPaymentQuac" class="mb-3">
        <span class="text-error">{{ errorPaymentQuac }}</span>
      </div>
    </div>
    <div v-if="stepCupo == 'TOKEN__SINGLE'">
      <p v-if="tempinfoUser">¡Hola {{ tempinfoUser.cliente_nombre }}!</p>
      <p v-if="tempinfoUser" class="text-center mb-5">
        Para finalizar el proceso de pago es necesario validar tu identidad,
        Ingresa el código de seguridad que enviamos a tu número de celular
        {{ tempinfoUser.cliente_celular }}
      </p>
      <div class="container-input-group-bono mb-4">
        <div class="input-simple-clover">
          <label
            class="label-input"
            :class="invalidCodToken ? 'invalid' : ''"
            :style="codigoToken ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Código</label
          >
          <input
            v-model="codigoToken"
            type="number"
            :class="invalidCodToken ? 'invalid-error' : ''"
            placeholder="Código"
            @blur="validateBlur('valCodigo')"
            @focus="invalidCodToken = false"
          />
          <span v-if="invalidCodToken" class="inavalida-input">{{
            codigoToken ? 'Código invalido' : 'Campo requerido'
          }}</span>
        </div>
        <div class="container-btn-group">
          <button
            class="btn-add-cod bnt-primary"
            @click="
              invalidCodToken || codigoToken == ''
                ? validateBlur('valCodigo')
                : validateDNI()
            "
          >
            Continuar
          </button>
        </div>
      </div>
      <div v-if="errorCupo" class="mb-3">
        <span class="text-error">{{ msgerrorCupo }}</span>
      </div>
      <p>¿No has recibido el código?</p>
      <div class="col-12 col-lg-8 col-xl-4 mx-auto">
        <div
          class="container-resend-code"
          :style="msgSended ? 'transition: all 1s linear; opacity: .3;' : ''"
          @click="
            msgSended
              ? ''
              : [
                  (msgSended = true),
                  (tempUrl = '/comunicaciones/transaccional/reenviarTokenSMS'),
                  getCodTokenSingle(),
                ]
          "
        >
          <img src="@/assets/icons/checkout/icon-sms.svg" />
          <p class="mb-0 ps-3">Reenviar código</p>
        </div>
        <!-- <div class="container-resend-code" @click="sendWhatsapp ? '' : sendWhatsapp = true;" :style="sendWhatsapp ? 'transition: all 1s linear; opacity: .3;' : ''">
                    <img src="@/assets/icons/checkout/icon-whatsapp.svg">
                    <p class="mb-0 ps-3">Recibir mensaje</p>
                </div> -->
        <div
          class="container-resend-code"
          :style="callSended ? 'transition: all 1s linear; opacity: .3;' : ''"
          @click="
            callSended
              ? ''
              : [
                  (callSended = true),
                  (tempUrl = '/comunicaciones/transaccional/reenviarTokenVoz'),
                  getCodTokenSingle(),
                ]
          "
        >
          <img src="@/assets/icons/checkout/icon-phone.svg" />
          <p class="mb-0 ps-3">Recibir llamada</p>
        </div>
      </div>
    </div>
    <div v-if="stepCupo == 'REQUIERE_SOLICITUD'">
      <p><b>¡Aún no tienes cupo personal!</b></p>
      <p class="mb-4">
        Paga tu compra en 10 cómodas cuotas quincenales con nuestro cupo
        personal. Aplica diligenciando el formulario a continuación.
      </p>
      <div class="container-form-cupo">
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidaDoc ? 'invalid' : ''"
              class="label-input"
              :style="
                dataCart.cliente_documento
                  ? 'top: -14px; z-index: 1'
                  : 'opacity: 0; z-index: -1;'
              "
              >Documento</label
            >
            <input
              v-model="dataCart.cliente_documento"
              type="number"
              :disabled="true"
              style="opacity: 0.6"
              :class="invalidaDoc ? 'invalid-error' : ''"
              placeholder="Documento"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidFechaExp ? 'invalid' : ''"
              class="label-input"
              :style="
                fechaExp ? 'top: -14px; z-index: 1' : 'opacity: 0; z-index: -1;'
              "
              >Fecha de expedición</label
            >
            <input
              v-model="fechaExp"
              type="date"
              :class="invalidFechaExp ? 'invalid-error' : ''"
              @blur="validateBlur('fechaExp')"
              @focus="invalidFechaExp = false"
            />
            <span v-if="invalidFechaExp" class="inavalida-input">{{
              fechaExp ? 'Fecha invalida' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidName ? 'invalid' : ''"
              class="label-input"
              :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Nombre</label
            >
            <input
              v-model="name"
              type="text"
              :class="invalidName ? 'invalid-error' : ''"
              placeholder="Nombre"
              autocomplete="given-name"
              @blur="validateBlur('name')"
              @focus="invalidName = false"
            />
            <span v-if="invalidName" class="inavalida-input">{{
              name ? 'Nombre invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidFamilyName ? 'invalid' : ''"
              class="label-input"
              :style="familyName ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Apellidos</label
            >
            <input
              v-model="familyName"
              type="text"
              :class="invalidFamilyName ? 'invalid-error' : ''"
              placeholder="Apellidos"
              autocomplete="family-name"
              @blur="validateBlur('familyName')"
              @focus="invalidFamilyName = false"
            />
            <span v-if="invalidFamilyName" class="inavalida-input">{{
              familyName ? 'Apellido invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              class="label-input"
              :style="selectDep ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Departamento</label
            >
            <select
              v-model="selectDep"
              :class="invalidSelectDep ? 'invalid-error' : ''"
              class="w-100"
              @blur="validateBlur('dep')"
              @focus="invalidSelectDep = false"
            >
              <option :value="null">Departamento</option>
              <option
                v-for="(item, index) in departamentos"
                :key="index"
                :value="item.codigo"
              >
                {{
                  item.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </option>
            </select>
            <span v-if="invalidSelectDep" class="inavalida-input"
              >Campo requerido</span
            >
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              class="label-input"
              :style="selectMun ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Ciudad</label
            >
            <select
              v-model="selectMun"
              :class="invalidSelectMun ? 'invalid-error' : ''"
              class="w-100"
              @blur="validateBlur('mun')"
              @focus="invalidSelectMun = false"
            >
              <option :value="null">Ciudad</option>
              <option
                v-for="(item, index2) in arrayMun"
                :key="index2"
                :value="item.codigo"
              >
                {{
                  item.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </option>
            </select>
            <span v-if="invalidSelectMun" class="inavalida-input"
              >Campo requerido</span
            >
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidTel ? 'invalid' : ''"
              class="label-input"
              :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Celular</label
            >
            <input
              v-model="tel"
              type="number"
              :class="invalidTel ? 'invalid-error' : ''"
              placeholder="Celular"
              autocomplete="tel"
              @blur="validateBlur('tel')"
              @focus="invalidTel = false"
            />
            <span v-if="invalidTel" class="inavalida-input">{{
              tel ? 'Celular invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
          <div class="input-simple-clover">
            <label
              :class="invalidCorreo ? 'invalid' : ''"
              class="label-input"
              :style="correo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Correo electrónico</label
            >
            <input
              id="email"
              v-model="correo"
              type="email"
              :class="invalidCorreo ? 'invalid-error' : ''"
              placeholder="Correo electrónico"
              autocomplete="email"
              @blur="validateBlur('correo')"
              @focus="invalidCorreo = false"
            />
            <span v-if="invalidCorreo" class="inavalida-input">{{
              correo ? 'Correo invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-12 my-4">
          Al hacer clic en "Continuar", acepta el
          <router-link
            class="link-primary"
            target="_blank"
            style="color: black !important"
            :to="{
              name: 'HabeasData',
              params: { politica: 'politica-de-privacidad' },
            }"
            >tratamiento de datos y consulta en centrales de
            riesgo.</router-link
          >
        </div>
        <div class="col-12 col-md-10 col-xl-6 mx-auto">
          <button class="bnt-primary w-100" @click="validateForm()">
            Continuar con el cupo personal
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="
        stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE' ||
          stepCupo == 'FIRMA__PAGARE_SMS' ||
          stepCupo == 'FIRMA__PAGARE_PREAPROBADO'
      "
    >
      <div class="">
        <p>
          <b>{{ nameCliente }}</b
          >, para finalizar el pedido es necesario ingresar la firma electrónica
          donde aceptas los términos y condiciones consignados en los
          documentos: Pagaré, Carta de instrucciones pagaré, Tratamiento de
          datos personales, Centrales de Riesgo y Garantías.
        </p>
      </div>
      <div class="d-flex flex-wrap align-content-center">
        <div class="col-12 col-xl-6 mx-auto d-flex p-2">
          <div class="data-box-pagare">
            <p class="mb-3 w-100">
              Pagaré y la Carta de Instrucciones del Pagaré
            </p>
            <div class="container-link">
              <a
                href="http://api.devstirpe.com.co/docs/pagare_carta_instrucciones.pdf"
                target="_blank"
                download
              >
                <svg
                  style="margin-right: 0.4rem; max-width: 15px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.163"
                  height="17.69"
                  viewBox="0 0 15.163 17.69"
                >
                  <path
                    id="Icon_metro-file-pdf"
                    data-name="Icon metro-file-pdf"
                    d="M17.063,5.955a2.3,2.3,0,0,1,.474.75,2.278,2.278,0,0,1,.2.869V18.946a.944.944,0,0,1-.948.948H3.518a.944.944,0,0,1-.948-.948V3.151A.944.944,0,0,1,3.518,2.2h8.845a2.279,2.279,0,0,1,.869.2,2.3,2.3,0,0,1,.75.474ZM12.68,3.546V7.258h3.712a1.079,1.079,0,0,0-.217-.4l-3.09-3.09a1.078,1.078,0,0,0-.4-.217ZM16.47,18.63V8.522H12.364a.944.944,0,0,1-.948-.948V3.467H3.834V18.63H16.47Z"
                    transform="translate(-2.571 -2.203)"
                  />
                </svg>
                <b>Ver documento</b>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 mx-auto d-flex p-2">
          <div class="data-box-pagare">
            <p class="mb-3 w-100">Leer Contrato de Crédito</p>
            <div class="container-link">
              <a
                href="http://api.devstirpe.com.co/docs/contrato_credito.pdf"
                target="_blank"
                download
              >
                <svg
                  style="margin-right: 0.4rem; max-width: 15px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.163"
                  height="17.69"
                  viewBox="0 0 15.163 17.69"
                >
                  <path
                    id="Icon_metro-file-pdf"
                    data-name="Icon metro-file-pdf"
                    d="M17.063,5.955a2.3,2.3,0,0,1,.474.75,2.278,2.278,0,0,1,.2.869V18.946a.944.944,0,0,1-.948.948H3.518a.944.944,0,0,1-.948-.948V3.151A.944.944,0,0,1,3.518,2.2h8.845a2.279,2.279,0,0,1,.869.2,2.3,2.3,0,0,1,.75.474ZM12.68,3.546V7.258h3.712a1.079,1.079,0,0,0-.217-.4l-3.09-3.09a1.078,1.078,0,0,0-.4-.217ZM16.47,18.63V8.522H12.364a.944.944,0,0,1-.948-.948V3.467H3.834V18.63H16.47Z"
                    transform="translate(-2.571 -2.203)"
                  />
                </svg>
                <b>Ver documento</b>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 mx-auto d-flex p-2">
          <div class="data-box-pagare">
            <p class="mb-3 w-100">Leer Contrato de Fianza</p>
            <div class="container-link">
              <a
                href="http://api.devstirpe.com.co/docs/contrato_fianza.pdf"
                target="_blank"
                download
              >
                <svg
                  style="margin-right: 0.4rem; max-width: 15px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.163"
                  height="17.69"
                  viewBox="0 0 15.163 17.69"
                >
                  <path
                    id="Icon_metro-file-pdf"
                    data-name="Icon metro-file-pdf"
                    d="M17.063,5.955a2.3,2.3,0,0,1,.474.75,2.278,2.278,0,0,1,.2.869V18.946a.944.944,0,0,1-.948.948H3.518a.944.944,0,0,1-.948-.948V3.151A.944.944,0,0,1,3.518,2.2h8.845a2.279,2.279,0,0,1,.869.2,2.3,2.3,0,0,1,.75.474ZM12.68,3.546V7.258h3.712a1.079,1.079,0,0,0-.217-.4l-3.09-3.09a1.078,1.078,0,0,0-.4-.217ZM16.47,18.63V8.522H12.364a.944.944,0,0,1-.948-.948V3.467H3.834V18.63H16.47Z"
                    transform="translate(-2.571 -2.203)"
                  />
                </svg>
                <b>Ver documento</b>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 mx-auto d-flex p-2">
          <div class="data-box-pagare">
            <p class="mb-3 w-100">
              Autorización Consultas a Centrales de Riesgo y Autorización
              Tratamiento de datos personales
            </p>
            <div class="container-link">
              <a
                href="http://api.devstirpe.com.co/docs/autorizacion_centrales_riesgo.pdf"
                target="_blank"
                download
              >
                <svg
                  style="margin-right: 0.4rem; max-width: 15px"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.163"
                  height="17.69"
                  viewBox="0 0 15.163 17.69"
                >
                  <path
                    id="Icon_metro-file-pdf"
                    data-name="Icon metro-file-pdf"
                    d="M17.063,5.955a2.3,2.3,0,0,1,.474.75,2.278,2.278,0,0,1,.2.869V18.946a.944.944,0,0,1-.948.948H3.518a.944.944,0,0,1-.948-.948V3.151A.944.944,0,0,1,3.518,2.2h8.845a2.279,2.279,0,0,1,.869.2,2.3,2.3,0,0,1,.75.474ZM12.68,3.546V7.258h3.712a1.079,1.079,0,0,0-.217-.4l-3.09-3.09a1.078,1.078,0,0,0-.4-.217ZM16.47,18.63V8.522H12.364a.944.944,0,0,1-.948-.948V3.467H3.834V18.63H16.47Z"
                    transform="translate(-2.571 -2.203)"
                  />
                </svg>
                <b>Ver documento</b>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-12 mx-auto p-2 mb-4">
          <div class="p-3 container-bg-info pb-0">
            <div class="container-input-checkbox">
              <input
                id="input-checkbox-tel"
                v-model="tycPagare"
                type="checkbox"
                checkmark="checkmark"
                class="mt-1 input-checkbox"
              />
              <span class="checkmark"></span>
              <label for="input-checkbox-tel"
                >Acepto todos los documentos registrados en la solicitud</label
              >
            </div>
          </div>
        </div>
        <div v-if="errorCupo" class="col-12 col-md-10 col-xl-6 mx-auto mb-3">
          <span class="text-error">{{ msgerrorCupo }}</span>
        </div>
        <div class="col-12 col-md-10 col-xl-6 mx-auto">
          <button
            class="btn-add-cod w-100 bnt-primary"
            :style="!tycPagare ? 'opacity: .6;' : ''"
            :disabled="!tycPagare"
            @click="validateDNI()"
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
    <div v-if="stepCupo == 'CUPO_INSUFICIENTE'" class="px-3">
      <div
        cols="12"
        class="text-center p-3 my-4 rounded"
        style="background-color: #ffe7cb"
      >
        <div
          class="col-md-10 py-1 col-12 mx-auto mb-4 rounded"
          style="background: #eaf0fc"
        >
          <strong
            >Tu cupo actual disponible para compras es: ${{
              avaibleSpace
            }}</strong
          >
        </div>
        <b>Pago con tu Cupo personal: ${{ totalCart }}</b>
        <p class="mt-4">
          Tu cupo NO alcanza para pagar la totalidad de la compra, puedes
          <b-link class="fw-bold" style="color: black" :to="{ name: 'Bag' }"
            >modificar tu carrito</b-link
          >
          o selecciona un nuevo método de pago
        </p>
      </div>
    </div>
    <div v-if="stepCupo == 'FINALIZAR_PEDIDO'" class="px-3">
      <div
        cols="12"
        class="text-center p-3 my-4 rounded"
        style="background-color: #ffe7cb"
      >
        <div
          class="col-md-10 py-1 col-12 mx-auto mb-4 rounded"
          style="background: #eaf0fc"
        >
          <strong
            >Tu cupo actual disponible para compras es: ${{
              cupoDisponibleFP
            }}</strong
          >
        </div>
        <b>Pago con tu Cupo personal: ${{ totalCartFP }}</b>
        <p class="mt-3">Tu cupo alcanza para pagar la totalidad de la compra</p>
        <div v-if="errorCupo" class="mb-3">
          <span class="text-error">{{ msgerrorCupo }}</span>
        </div>
        <button class="col-12 col-lg-6 bnt-primary" @click="validateDNI()">
          Finalizar compra
        </button>
      </div>
    </div>
    <b-modal
      id="modal-token-cel"
      ref="modal-"
      v-model="modalTokenCel"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div v-if="!cancelFirme">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          class="btn-close-modal-gray"
          @click="cancelFirme = true"
        >
          <g
            id="Grupo_6059"
            data-name="Grupo 6059"
            transform="translate(-5105.212 99.512)"
          >
            <path
              id="Trazado_6"
              data-name="Trazado 6"
              d="M1.509.214,13.746,0A1.422,1.422,0,0,1,15.2,1.457a1.547,1.547,0,0,1-1.509,1.509L1.457,3.181A1.422,1.422,0,0,1,0,1.724,1.547,1.547,0,0,1,1.509.214Z"
              transform="translate(5107.461 -99.512) rotate(45)"
              fill="#c2c2c2"
            />
            <path
              id="Trazado_7"
              data-name="Trazado 7"
              d="M1.457,0,13.694.214A1.547,1.547,0,0,1,15.2,1.724a1.422,1.422,0,0,1-1.457,1.457L1.509,2.967A1.547,1.547,0,0,1,0,1.457,1.422,1.422,0,0,1,1.457,0Z"
              transform="translate(5105.212 -88.761) rotate(-45)"
              fill="#c2c2c2"
            />
          </g>
        </svg>
        <div v-if="sendSMSToken">
          <p class="text-center mb-5">
            Ahora ingresa el código que enviaremos a tu correo electrónico
            <b>{{ emailFP }}</b>
          </p>
        </div>
        <div v-else>
          <p class="text-center">
            Hola {{ nameCliente }}, es tu primera compra con nuestro cupo, para
            continuar debes realizar la firma del pagaré electrónica.
          </p>
          <p class="text-center mb-5">
            Ingresa el código que te enviaremos vía <b>SMS</b> a tu celular
            <b>No. {{ telCP }}</b>
          </p>
        </div>
        <div class="container-actions-token">
          <div class="d-flex justify-content-center">
            <img
              v-if="sendSMSToken"
              src="@/assets/icons/send-email-token.svg"
              style="margin-top: -32px; margin-bottom: 1rem"
            />
            <img
              v-else
              src="@/assets/icons/send-sms-token.svg"
              style="margin-top: -32px; margin-bottom: 1rem"
            />
          </div>
          <p class="text-center">
            <b
              >Ingresa el código enviado a tu
              {{ sendSMSToken ? 'correo' : 'celular' }}</b
            >
          </p>
          <div class="content-inputs">
            <input
              id="input-1"
              v-model="inputVal1"
              type="number"
              pattern="[0-9]"
              maxlength="1"
              :class="msgError ? 'invalid-cod' : ''"
              @keyup="inputTokenAndNext(inputVal1, 'input-2')"
            />
            <input
              id="input-2"
              v-model="inputVal2"
              type="number"
              pattern="[0-9]"
              maxlength="1"
              :class="msgError ? 'invalid-cod' : ''"
              @keyup="inputTokenAndNext(inputVal2, 'input-3')"
            />
            <input
              id="input-3"
              v-model="inputVal3"
              type="number"
              pattern="[0-9]"
              maxlength="1"
              :class="msgError ? 'invalid-cod' : ''"
              @keyup="inputTokenAndNext(inputVal3, 'input-4')"
            />
            <input
              id="input-4"
              v-model="inputVal4"
              type="number"
              pattern="[0-9]"
              maxlength="1"
              :class="msgError ? 'invalid-cod' : ''"
              @keyup="inputTokenAndNext(inputVal3, 'btn-send-cod-soli')"
            />
          </div>
          <div v-if="msgError" class="invalid-cod-msg">
            <p class="mb-0">{{ messagerError }}</p>
          </div>
          <button
            id="btn-send-cod-soli"
            class="bnt-primary w-100"
            @click="
              sendSMSToken ? validateTokenEmailFirma() : validateTokenCelFirma()
            "
          >
            Continuar
            <svg
              style="margin-left: 0.4rem"
              xmlns="http://www.w3.org/2000/svg"
              width="15.54"
              height="16.782"
              viewBox="0 0 15.54 16.782"
            >
              <g
                id="Icon_feather-arrow-left"
                data-name="Icon feather-arrow-left"
                transform="translate(14.04 14.661) rotate(180)"
              >
                <path
                  id="Trazado_102"
                  data-name="Trazado 102"
                  d="M12.54,0H0"
                  transform="translate(0 6.27)"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Trazado_103"
                  data-name="Trazado 103"
                  d="M6.27,12.54,0,6.27,6.27,0"
                  fill="none"
                  stroke="#FFFFFF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </g>
            </svg>
          </button>
        </div>
        <p class="text-center">¿No has recibido el código?</p>
        <button
          v-if="!sendSMSToken"
          id="btn-resend-code-sms"
          :disabled="reSendCod1"
          :style="reSendCod1 ? 'opacity: .6' : ''"
          class="w-100 bnt-outline mb-3"
          @click="reSendSMSPagare()"
        >
          Reenviar código
        </button>
        <button
          v-else
          id="btn-resend-code-email"
          :disabled="reSendCod2"
          :style="reSendCod2 ? 'opacity: .6' : ''"
          class="w-100 bnt-outline mb-3"
          @click="reSendEmailPagare()"
        >
          Reenviar código
        </button>
        <p class="text-center">
          Si tienes algún inconveniente, comunícate al <b>01 8000 180566</b> o a
          nuestro
          <b
            ><a
              style="color: #8b9ca2"
              href="https://api.whatsapp.com/send?phone=573208743430&text=asesor"
              >WhatsApp: 320 874 3430</a
            ></b
          >
        </p>
      </div>
      <div v-else>
        <p class="text-center mb-4">
          <b
            >¿Seguro que quieres abandonar el proceso de firma de pagaré? Está
            acción cancelará tu solicitud y no podrás finalizar la compra.</b
          >
        </p>
        <button
          :key="cancelFirme"
          class="bnt-primary w-100 mb-4"
          @click="cancelFirme = false"
        >
          Continuar proceso de firma
        </button>
        <button
          :key="cancelFirme"
          class="w-100 mb-4 mx-auto bnt-outline"
          @click="modalTokenCel = false"
        >
          Abandonar y cancelar compra
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      errorPaymentQuac: '',
      stepCupo: '',
      url: '/payment/cupopersonal/validarDocumentoClienteCupo',
      errorCupo: false,
      msgerrorCupo: '',
      tempUrl: '',
      arrayMun: [],
      msgError: '',
      tempinfoUser: null,
      /** Finalizar compra */
      cupoDisponibleFP: null,
      docFP: '',
      cupoCodigoSMSFP: '',
      tokenProcessFP: '',
      totalCartFP: '',
      /** Variables send Doc **/
      cedulaCupo: '',
      invalidDocCupo: false,
      formId: 1,
      msgSended: false,
      callSended: false,
      /** Variables para crear solicitud **/
      correo: '',
      invalidCorreo: false,
      tel: '',
      invalidTel: false,
      name: '',
      invalidName: false,
      familyName: '',
      invalidFamilyName: false,
      doc: '',
      invalidaDoc: false,
      fechaExp: null,
      invalidFechaExp: false,
      selectDep: null,
      invalidSelectDep: false,
      selectMun: null,
      invalidSelectMun: false,
      /** Variables para tokenización **/
      codigoToken: '',
      invalidCodToken: false,
      /** Variables sendCode **/
      sendSms: false,
      sendWhatsapp: false,
      sendPhone: false,
      /** Variables TyCPagare **/
      tycPagare: false,
      /** Variables cod phone, cod email **/
      codigoPagarePhone: '',
      invalidCodPhonePagare: false,
      codigoPagareEmail: '',
      invalidCodEmailPagare: false,
      resendCod: '',
      countTimeResendCodPagare: 0,
      /** Variables cupo insuficiente */
      avaibleSpace: '',
      totalCart: '',
      /** Firma agare */
      modalTokenCel: false,
      inputVal1: '',
      inputVal2: '',
      inputVal3: '',
      inputVal4: '',
      sendSMSToken: false,
      cancelFirme: false,
      telCP: '',
      codSMSFP: '',
      tempURLFP: '',
      tempFormIdFP: '',
      nameCliente: '',
      emailFP: '',
      countTimeResendCodPagareSMS: 0,
      countTimeResendCodPagareEMAIL: 0,
      loading: false,
      reSendCod1: false,
      reSendCod2: false,
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    ...mapState('user', ['departamentos']),
    ...mapState(['esFE']),
    validateEmail() {
      /* eslint-disable */
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validateTel() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
    validateDocCupo() {
      return this.doc.length > 4 && this.doc.length <= 10
    },
    validateDoc() {
      return this.doc.length > 4 && this.doc.length <= 10
    },
    // validateDocCupo() {
    //   return this.cedulaCupo.length > 4 && this.cedulaCupo.length <= 10
    // },
    validateFechaExp() {
      if (this.fechaExp != null) {
        let dateSplit = this.fechaExp.split('-')
        let dateNow = new Date()
        if (dateNow.getFullYear() > parseInt(dateSplit[0])) {
          return true
        } else if (dateNow.getFullYear() == parseInt(dateSplit[0])) {
          if (dateNow.getMonth() + 1 > parseInt(dateSplit[1])) {
            return true
          } else if (dateNow.getMonth() + 1 == dateSplit[1]) {
            if (dateNow.getDate() > parseInt(dateSplit[2])) {
              return true
            } else if (dateNow.getDate() == dateSplit[2]) {
              return true
            }
          }
        }
      }
      return false
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla.name,
          brand: product.marca,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    formId() {
      if (this.formId == 5) {
        this.updateCountTimeResendCodPagare(60)
      }
    },
    modalTokenCel() {
      if (!this.modalTokenCel) {
        this.url = this.tempURLFP
        this.stepCupo = this.tempFormIdFP
        this.inputVal1 = ''
        this.inputVal2 = ''
        this.inputVal3 = ''
        this.inputVal4 = ''
        this.sendSMSToken = false
        this.cancelFirme = false
      }
    },
    selectDep() {
      if (this.selectDep) {
        this.selectMun = null
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
    departamentos() {
      if (this.selectDep) {
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
  },
  async mounted() {
    if (this.departamentos.length == 0) {
      await this.getCiudades()
    }
  },
  methods: {
    ...mapActions('cart', ['updateInfoCart']),
    ...mapActions('user', ['getCiudades']),
    inputTokenAndNext(prev, next) {
      this.inputVal1 = this.inputVal1 ? this.inputVal1[this.inputVal1.length - 1] : ''
      this.inputVal2 = this.inputVal2 ? this.inputVal2[this.inputVal2.length - 1] : ''
      this.inputVal3 = this.inputVal3 ? this.inputVal3[this.inputVal3.length - 1] : ''
      this.inputVal4 = this.inputVal4 ? this.inputVal4[this.inputVal4.length - 1] : ''
      if (prev.length > 0) {
        document.getElementById(next).focus()
      }
    },
    async validateTokenCelFirma() {
      this.msgError = false
      this.messagerError = ''
      let code =
        this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4
      if (code.length == 4) {
        this.validateDNI()
      } else {
        this.msgError = true
        this.messagerError = 'Código requerido'
      }
    },
    async validateTokenEmailFirma() {
      this.msgError = false
      this.messagerError = ''
      let code =
        this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4
      if (code.length == 4) {
        this.validateDNI()
      } else {
        this.msgError = true
        this.messagerError = 'Código requerido'
      }
    },
    async getCodTokenSingle() {
      let data = new FormData()
      data.append('documento', this.dataCart.cliente_documento)
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: this.tempUrl,
        data: data,
      }).then(response => {
        this.msgError = true
        this.messagerError = response.data.response.message
        this.type = 'success'
      })
    },
    async reSendSMSPagare() {
      let data = new FormData()
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/cupopersonal/reenviarCodigoSMSPagare',
        data: data,
      }).then(() => {
        this.reSendCod1 = true
        //this.updateCountTimeResendCodPagareSMS(60);
      })
    },
    async reSendEmailPagare() {
      let data = new FormData()
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/cupopersonal/reenviarCodigoEMAILPagare',
        data: data,
      }).then(() => {
        this.reSendCod2 = true
        //this.updateCountTimeResendCodPagareEMAIL(60);
      })
    },
    validateBlur(input) {
      if (input == 'correo') {
        this.invalidCorreo = !this.validateEmail
      } else if (input == 'tel') {
        this.invalidTel = !this.validateTel
      } else if (input == 'name') {
        let re = /[a-zA-Z]{3,}/
        this.invalidName = !re.test(this.name)
      } else if (input == 'familyName') {
        let re = /[a-zA-Z]{3,}/
        this.invalidFamilyName = !re.test(this.familyName)
      } else if (input == 'doc') {
        this.invalidaDoc = !this.validateDoc
      } else if (input == 'fechaExp') {
        this.invalidFechaExp = !this.validateFechaExp
      } else if (input == 'mun') {
        this.invalidSelectMun = !this.selectMun
      } else if (input == 'dep') {
        this.invalidSelectDep = !this.selectDep
      } else if (input == 'docCupo') {
        this.invalidDocCupo = !(
          this.cedulaCupo.length > 4 && this.cedulaCupo.length < 11
        )
      } else if (input == 'valCodigo') {
        this.invalidCodToken = !(this.codigoToken.length == 6)
      }
    },
    validateForm() {
      if (
        this.validateEmail &&
        this.validateFechaExp &&
        this.validateTel &&
        this.name.length > 2 &&
        this.familyName.length > 2 &&
        this.selectMun &&
        this.selectDep
      ) {
        this.validateDNI()
      } else {
        this.invalidCorreo = !this.validateEmail
        this.invalidTel = !this.validateTel
        this.invalidName = !(this.name.length > 2)
        this.invalidFamilyName = !(this.familyName.length > 2)
        this.invalidaDoc = !this.validateDoc
        this.invalidSelectMun = !this.selectMun
        this.invalidSelectDep = !this.selectDep
        this.invalidFechaExp = !this.validateFechaExp
      }
    },
    sendDocCupo() {
      if (this.dataCart.cliente_documento) {
      }
    },
    sendTokenCupo() {
      if (this.codigoToken.length == 6) {
        this.formId += 1
      } else {
        this.invalidCodToken = true
      }
    },
    async updateCountTimeResendCodPagare(time) {
      let btnChange = document.getElementById('btn-resend-codes')
      this.countTimeResendCodPagare = time
      if (time > 0) {
        if (btnChange) {
          btnChange.innerHTML = 'Reenviar códigos en ' + time.toString()
        }
        await setTimeout(() => {
          this.updateCountTimeResendCodPagare(time - 1)
        }, 1000)
      } else {
        if (btnChange) {
          btnChange.innerHTML = 'Reenviar códigos'
        }
        this.countTimeResendCodPagare = 0
      }
    },
    resendCodigosPagare() {
      this.updateCountTimeResendCodPagare(60)
    },
    sendCodigosPagare() {
      if (
        this.codigoPagareEmail.length == 6 &&
        this.codigoPagarePhone.length == 6
      ) {
      } else {
      }
    },
    validateCodPagare() {
      if (
        this.codigoPagareEmail.length == 4 &&
        this.codigoPagarePhone.length == 4
      ) {
        this.formId += 1
      } else {
        this.invalidCodEmailPagare = !(this.codigoPagareEmail.length == 4)
        this.invalidCodPhonePagare = !(this.codigoPagarePhone.length == 4)
      }
    },
    async validateCupoQuac() {
      this.$store.state.loaderClover = true
      this.$store.state.cart.msgModalExcepcion406 = ''
      this.$store.state.cart.btnModalExcepcion406 = ''
      let data = new FormData()
      const token = window.localStorage.getItem('tokenCart')
      data.append('token', token)
      sendFBQEvent(this, 'InitiateCheckout', {})
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/quac/compra',
        data: data,
      })
        .then(response => {
          GA4AddPayment('Quac')
          GA4Purchase()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderClover = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 500) {
            this.errorPaymentQuac =
              'Parece que algo salió mal, por favor intenta más tarde.'
          } else if (err.response.status == 406) {
            this.$store.state.cart.msgModalExcepcion406 =
              err.response.data.errors.bono_regalo[0]
            this.$store.state.cart.btnModalExcepcion406 = 'cupo'
          } else if (err.response.data.message) {
            this.errorPaymentQuac = err.response.data.message
          } else {
            let message = ''
            for (const property in err.response.data.errors) {
              message = message + err.response.data.errors[property] + ' '
            }
            this.errorPaymentQuac = message
          }
        })
    },
    async validateDNI() {
      this.$store.state.loaderClover = true
      this.$store.state.cart.msgModalExcepcion406 = ''
      this.$store.state.cart.btnModalExcepcion406 = ''
      if (!this.loading) {
        this.validateData = false
        this.loading = true
        let data = new FormData()
        this.errorCupo = false
        this.msgerrorCupo = ''
        this.msgError = false
        this.messagerError = ''
        data.append(
          'token',
          this.$route.params.tokenCart
            ? this.$route.params.tokenCart
            : window.localStorage.getItem('tokenCart'),
        )
        data.append('pedido_fe_dian', this.esFE)
        if (this.stepCupo == '') {
          data.append('documento', this.dataCart.cliente_documento)
        } else if (this.stepCupo == 'REQUIERE_SOLICITUD') {
          this.validateData = false
          sendFBQEvent(this, 'Lead', {})
          data.append('correo1', this.correo)
          data.append('documento', this.dataCart.cliente_documento)
          data.append('nombres', this.name)
          data.append('apellidos', this.familyName)
          data.append('ciudad', this.selectMun)
          data.append('departamento', this.selectDep)
          data.append('celular1', this.tel)
          data.append('fecha_expedicion', this.fechaExp)
        } else if (this.stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE') {
          data.append('documento', this.documentCP)
        } else if (this.stepCupo == 'FIRMA__PAGARE_SMS') {
          data.append(
            'cupoCodigoSMS',
            this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4,
          )
        } else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
          data.append('cupoCodigoSMS', this.codSMSFP)
          data.append(
            'cupoCodigoCorreo',
            this.inputVal1 + this.inputVal2 + this.inputVal3 + this.inputVal4,
          )
        } else if (this.stepCupo == 'TOKEN__SINGLE') {
          data.append('documento', this.dataCart.cliente_documento)
          data.append('cupoCodigoSMS', this.codigoToken)
          data.append('tokenProcess', this.tokenProcessTCP)
        } else if (this.stepCupo == 'ACTIVACION_CUPO') {
          data.append('documento', this.clientNITAC)
          data.append('nombres', this.clientNameAC)
          data.append('apellidos', this.clientLastnameAC)
          data.append('celular', this.clientCelAC)
          data.append('correo', this.clientEmailAC)
          data.append('clave', this.clientPassAC)
          data.append('clave2', this.clientConfPassAC)
          data.append('cupoCodigoSMS', this.clientCodSMSAC)
          data.append('tokenProcess', this.tokenProcessAC)
        } else if (this.stepCupo == 'FINALIZAR_PEDIDO') {
          sendFBQEvent(this, 'InitiateCheckout', {})
          data.append('documento', this.docFP)
          data.append('cupoCodigoSMS', this.cupoCodigoSMSFP)
          data.append('tokenProcess', this.tokenProcessFP)
        }
        // try {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: this.url,
          data: data,
        })
          .then(response => {
            this.loading = false
            this.$store.state.loaderClover = false
            if (response.data.response.form_id != 'RESUMEN_PEDIDO') {
              this.url = response.data.response.data_form.url
              this.stepCupo = response.data.response.data_form.form_id
              this.formHTLM = response.data.form
              this.step = 2
              if (this.stepCupo == 'REQUIERE_SOLICITUD') {
                this.soliCC = parseFloat(
                  response.data.response.data_form.cliente_documento,
                )
              } else if (this.stepCupo == 'ENVIAR_CODIGO_SMS_PAGARE') {
                this.documentCP =
                  response.data.response.data_form.cliente_documento
                this.cupoDisponible =
                  response.data.response.data_form.cupo_disponible
                this.totalCarrito =
                  response.data.response.data_form.totalCarrito
                this.tempURLFP = response.data.response.data_form.url
                this.tempFormIdFP = response.data.response.data_form.form_id
                this.nameCliente =
                  response.data.response.data_form.cliente_nombre
              } else if (this.stepCupo == 'FIRMA__PAGARE_SMS') {
                this.modalTokenCel = true
                this.telCP = response.data.response.data_form.cliente_celular
                //this.updateCountTimeResendCodPagareSMS(60);
              } else if (this.stepCupo == 'FIRMA__PAGARE_PREAPROBADO') {
                this.sendSMSToken = true
                this.emailFP = response.data.response.data_form.cliente_correo
                this.codSMSFP =
                  this.inputVal1 +
                  this.inputVal2 +
                  this.inputVal3 +
                  this.inputVal4
                this.inputVal1 = ''
                this.inputVal2 = ''
                this.inputVal3 = ''
                this.inputVal4 = ''
                if(response.data.response.data_form.error_crm_correo != '') {
                  this.msgError = true
                  this.messagerError = response.data.response.data_form.error_crm_correo
                }
                //this.updateCountTimeResendCodPagareEMAIL(60);
              } else if (this.stepCupo == 'TOKEN__SINGLE') {
                this.$store.state.cart.dataCupo =
                  response.data.response.data_form
                this.url = response.data.response.data_form.url
                this.tokenProcessTCP =
                  response.data.response.data_form.tokenProcess
                this.tempinfoUser = response.data.response.data_form
              } else if (this.stepCupo == 'CUPO_INSUFICIENTE') {
                this.avaibleSpace =
                  response.data.response.data_form.cupo_disponible
                this.totalCart = response.data.response.data_form.total_carrito
              } else if (this.stepCupo == 'ACTIVACION_CUPO') {
                this.clientNameAC =
                  response.data.response.data_form.cliente_nombre
                this.clientLastnameAC =
                  response.data.response.data_form.cliente_apellidos
                this.clientNITAC =
                  response.data.response.data_form.cliente_documento
                this.clientCelAC =
                  response.data.response.data_form.cliente_celular
                this.clientEmailAC =
                  response.data.response.data_form.cliente_correo
                this.cupoDisponibleAC =
                  response.data.response.data_form.cupo_disponible
                this.totalCarritoAC =
                  response.data.response.data_form.totalCarrito
                this.clientCodSMSAC =
                  response.data.response.data_form.cupoCodigoSMS
                this.tokenProcessAC =
                  response.data.response.data_form.tokenProcess
              } else if (this.stepCupo == 'FINALIZAR_PEDIDO') {
                this.docFP = response.data.response.data_form.cliente_documento
                this.cupoCodigoSMSFP =
                  response.data.response.data_form.cupoCodigoSMS
                this.cupoDisponibleFP =
                  response.data.response.data_form.cupo_disponible
                this.tokenProcessFP =
                  response.data.response.data_form.tokenProcess
                this.totalCartFP =
                  response.data.response.data_form.total_carrito
              }
            } else {
              this.$store.state.cart.dataSummaryPayment = response.data.response
              // CL-CheckoutPayment
              window.dataLayer.push({
                event: 'CL-CheckoutPayment',
                step: 4,
                carrito_id: this.dataCart.carrito_id,
                ecommerce: {
                  currencyCode: 'COP',
                  products: this.getDataAnalytics,
                  subtotal: this.dataCart.carrito_subtotal,
                  total: this.dataCart.carrito_total,
                },
                profile: {
                  email: this.dataCart.cliente_correo,
                  tipo_doc: this.dataCart.tipodoc,
                  documento: this.dataCart.cliente_documento,
                  nombre: this.dataCart.cliente_nombre,
                  apellidos: this.dataCart.cliente_apellidos,
                  celular: this.dataCart.cliente_celular,
                },
                shipping: {
                  direccion: this.dataCart.cliente_direccion,
                  barrio: this.dataCart.cliente_barrio,
                  departamento_id: this.dataCart.cliente_departamento,
                  ciudad_id: this.dataCart.cliente_ciudad,
                  observaciones: this.dataCart.observaciones,
                },
                payment: {
                  total: this.dataCart.carrito_total,
                  paymentMethod: 'Quac',
                  coupon: this.dataCart.cupon_codigo,
                },
                value: this.dataCart.carrito_total,
              })
              sendFBQEvent(this, 'Purchase', {
                value: response.data.response.pedido.carrito.carrito_total,
                currency: 'COP',
                contents: response.data.response.productos_purchase,
                content_ids: response.data.response.productos_purchase.map(
                  e => e.id,
                ),
                event_id: response.data.response.pedido.pedido.pedido_reference_sale,
                content_type: 'product',
              })
            }
          })
          .catch(err => {
            this.$store.state.loaderClover = false
            this.errorCupo = true
            if (e.response.status == 423) {
              // this.$store.state.dataModal423.descripcion =
              //   e.response.data.errors.carrito[0]
              // this.$store.state.dataModal423.routeName =
              //   e.response.data.redireccionar_catalogo &&
              //   e.response.data.redireccionar_catalogo == 1
              //     ? 'Store'
              //     : 'PaymentEmail'
              // this.$store.state.showModal423 = true
              this.$bvModal.show('modal-products-sold-out')
            } else if (err.response.status == 500) {
              this.msgError = true
              this.msgerrorCupo =
                'Parece que algo salió mal, por favor intenta más tarde.'
              this.loading = false
              this.tempUrl = ''
            } else if (err.response.status == 406) {
              this.$bvModal.show('modal-error-406')
              this.$store.state.cart.msgModalExcepcion406 =
                err.response.data.errors.bono_regalo[0]
              this.$store.state.cart.btnModalExcepcion406 = 'cupo'
            } else {
              var messages = ``
              if (err.response.data.message) {
                messages = err.response.data.message
              } else {
                var obj = err.response.data.errors
                for (const prop in obj) {
                  messages += `${obj[prop][0]}\n`
                }
              }
              this.msgError = true
              this.messagerError = messages
              this.msgerrorCupo = messages
              this.loading = false
              this.$store.state.loaderClover = false
              this.tempUrl = ''
            }
          })
        // } catch (err) {
        //     this.textNotification = 'Parece que algo salió mal, por favor intenta más tarde.';
        //     console.log('No se que paso!')
        // }
      }
      this.loading = false
      this.$store.state.loaderClover = false
    },
  },
}
</script>
