<template>
  <div class="container-payment-credit-card col-12 col-lg-11 col-xl-8 mx-auto">
    <div class="w-100 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover position-relative">
        <label
          :class="invalidNCreditCard ? 'invalid' : ''"
          class="label-input"
          :style="nCreditCard ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Número de tarjeta</label
        >
        <input
          v-model="formatCardNumber"
          type="text"
          :class="invalidNCreditCard ? 'invalid-error' : ''"
          placeholder="Número de tarjeta"
          pattern="\d*"
          maxlength="19"
          @blur="validateBlur('creditCard')"
          @focus="invalidNCreditCard = false"
          @input="updateValue"
        />
        <span v-if="invalidNCreditCard" class="inavalida-input">{{
          nCreditCard ? 'Tarjeta invalida' : 'Campo requerido'
        }}</span>
        <img
          v-if="typeCard == 'visa'"
          src="@/assets/icons/checkout/visa.svg"
          class="credit-card"
        />
        <img
          v-if="typeCard == 'mastercard'"
          src="@/assets/icons/checkout/mastercard.svg"
          class="credit-card"
        />
        <img
          v-if="typeCard == 'diners'"
          src="@/assets/icons/checkout/diners.svg"
          class="credit-card"
        />
        <img
          v-if="typeCard == 'codensa'"
          src="@/assets/icons/checkout/codensa.svg"
          class="credit-card"
        />
        <img
          v-if="typeCard == 'amex'"
          src="@/assets/icons/checkout/americanexpress.svg"
          class="credit-card"
        />
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidMonth ? 'invalid' : ''"
          class="label-input"
          :style="month ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Mes</label
        >
        <select
          v-model="month"
          :class="invalidMonth ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('month')"
          @focus="invalidMonth = false"
        >
          <option :value="null">Mes</option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <span v-if="invalidMonth" class="inavalida-input">Campo requerido</span>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidYear ? 'invalid' : ''"
          class="label-input"
          :style="year ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Año</label
        >
        <select
          v-model="year"
          :class="invalidYear ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('year')"
          @focus="invalidYear = false"
        >
          <option :value="null">Año</option>
          <option :value="new Date().getFullYear()">
            {{ new Date().getFullYear() }}
          </option>
          <option :value="new Date().getFullYear() + 1">
            {{ new Date().getFullYear() + 1 }}
          </option>
          <option :value="new Date().getFullYear() + 2">
            {{ new Date().getFullYear() + 2 }}
          </option>
          <option :value="new Date().getFullYear() + 3">
            {{ new Date().getFullYear() + 3 }}
          </option>
          <option :value="new Date().getFullYear() + 4">
            {{ new Date().getFullYear() + 4 }}
          </option>
          <option :value="new Date().getFullYear() + 5">
            {{ new Date().getFullYear() + 5 }}
          </option>
          <option :value="new Date().getFullYear() + 6">
            {{ new Date().getFullYear() + 6 }}
          </option>
          <option :value="new Date().getFullYear() + 7">
            {{ new Date().getFullYear() + 7 }}
          </option>
          <option :value="new Date().getFullYear() + 8">
            {{ new Date().getFullYear() + 8 }}
          </option>
          <option :value="new Date().getFullYear() + 9">
            {{ new Date().getFullYear() + 9 }}
          </option>
          <option :value="new Date().getFullYear() + 10">
            {{ new Date().getFullYear() + 10 }}
          </option>
          <option :value="new Date().getFullYear() + 11">
            {{ new Date().getFullYear() + 11 }}
          </option>
          <option :value="new Date().getFullYear() + 12">
            {{ new Date().getFullYear() + 12 }}
          </option>
          <option :value="new Date().getFullYear() + 13">
            {{ new Date().getFullYear() + 13 }}
          </option>
        </select>
        <span v-if="invalidYear" class="inavalida-input">Campo requerido</span>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidCcv ? 'invalid' : ''"
          class="label-input"
          :style="doc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >CCV</label
        >
        <input
          v-model="ccv"
          type="text"
          :class="invalidCcv ? 'invalid-error' : ''"
          maxlength="4"
          placeholder="CCV"
          pattern="/[0-9]{4}/"
          @blur="validateBlur('ccv')"
          @focus="invalidCcv = false"
        />
        <span v-if="invalidCcv" class="inavalida-input">{{
          ccv ? 'CCV invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4 d-flex align-items-center">
      <b-link
        id="link-popover"
        style="cursor: pointer; text-decoration: none; color: black"
      >
        <img class="pe-3" src="@/assets/icons/checkout/credit-card.svg" />
        ¿Dónde encuentro el código CVV?</b-link
      >
      <b-popover placement="bottom" target="link-popover" triggers="hover">
        <p><b>Código de seguridad CVV</b></p>
        <p style="font-size: 14px">
          Los últimos tres (3) o cuatro (4) dígitos del número impreso al
          respaldo de tu tarjeta corresponden al código de seguridad (CVV) o
          CVV2. Si tienes una tarjeta de crédito American Express, el código lo
          encuentra en la parte delantera.
        </p>
      </b-popover>
    </div>
    <div class="w-100 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidNcuotas ? 'invalid' : ''"
          class="label-input"
          :style="nCuotas ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Número de cuotas</label
        >
        <select
          v-model="nCuotas"
          :class="invalidNcuotas ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('ncuotas')"
          @focus="invalidNcuotas = false"
        >
          <option :value="null">Número de cuotas</option>
          <option value="1">1 cuota mensual</option>
          <option value="2">2 cuotas mensuales</option>
          <option value="3">3 cuotas mensuales</option>
          <option value="4">4 cuotas mensuales</option>
          <option value="5">5 cuotas mensuales</option>
          <option value="6">6 cuotas mensuales</option>
          <option value="7">7 cuotas mensuales</option>
          <option value="8">8 cuotas mensuales</option>
          <option value="9">9 cuotas mensuales</option>
          <option value="10">10 cuotas mensuales</option>
          <option value="11">11 cuotas mensuales</option>
          <option value="12">12 cuotas mensuales</option>
        </select>
        <span v-if="invalidNcuotas" class="inavalida-input"
          >Campo requerido</span
        >
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidTipoDoc ? 'invalid' : ''"
          class="label-input"
          :style="tipoDoc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Tipo</label
        >
        <select
          v-model="tipoDoc"
          :class="invalidTipoDoc ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('tipoDoc')"
          @focus="invalidTipoDoc = false"
        >
          <option :value="null">Tipo</option>
          <option value="CC">CC</option>
          <option value="CE">CE</option>
          <option value="PAS">PAS</option>
          <option value="NIT">NIT</option>
        </select>
        <span v-if="invalidTipoDoc" class="inavalida-input"
          >Campo requerido</span
        >
      </div>
    </div>
    <div class="col-12 col-lg-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidDoc ? 'invalid' : ''"
          class="label-input"
          :style="doc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Documento</label
        >
        <input
          v-model="doc"
          type="number"
          :class="invalidDoc ? 'invalid-error' : ''"
          placeholder="Documento"
          @blur="validateBlur('doc')"
          @focus="invalidDoc = false"
        />
        <span v-if="invalidDoc" class="inavalida-input">{{
          doc ? 'Documento invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <p class="mb-2 px-xl-3">Nombre y apellido como aparece en la tarjeta</p>
    <div class="w-100 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidName ? 'invalid' : ''"
          class="label-input"
          :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Titular de la tarjeta</label
        >
        <input
          v-model="name"
          type="text"
          :class="invalidName ? 'invalid-error' : ''"
          placeholder="Titular de la tarjeta"
          autocomplete="given-name"
          @blur="validateBlur('name')"
          @focus="invalidName = false"
        />
        <span v-if="invalidName" class="inavalida-input">{{
          name ? 'Nombre invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="w-100 px-2 px-xl-3 mb-4 ms-auto">
      <button class="bnt-primary w-100" @click="sendData()">
        Finalizar compra
      </button>
    </div>
    <div v-if="errorPayment">
      <span class="text-error" v-html="msgerrorPayment"></span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      name: '',
      invalidName: false,
      nCreditCard: '',
      isValidCard: false,
      invalidNCreditCard: false,
      tipoDoc: null,
      invalidTipoDoc: false,
      doc: '',
      invalidDoc: false,
      month: null,
      invalidMonth: false,
      year: null,
      invalidYear: false,
      ccv: '',
      invalidCcv: false,
      nCuotas: null,
      invalidNcuotas: false,
      typeCard: '',
      errorPayment: false,
      msgerrorPayment: '',
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    ...mapState(['esFE']),
    validateName() {
      let re = /[a-zA-Z]{3,}/
      return re.test(this.name)
    },
    validateDoc() {
      if (this.tipoDoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.doc) && this.doc.length >= 7 && this.doc.length <= 10
        )
      } else if (this.tipoDoc == 'CE') {
        const re2 = /([0-9]){6}/
        return re2.test(this.doc) && this.doc >= 6
      } else if (this.tipoDoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return re3.test(this.doc) && this.doc.length >= 5
      } else if (this.tipoDoc == 'NIT') {
        return this.doc.length == 9
      }
      return false
    },
    formatCardNumber() {
      return this.nCreditCard != ''
        ? this.nCreditCard.match(/.{1,4}/g).join(' ')
        : ''
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla.name,
          brand: product.marca,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  methods: {
    updateValue(e) {
      this.nCreditCard = e.target.value.replace(/ /g, '')
    },
    validateBlur(input) {
      if (input == 'name') {
        this.invalidName = !this.validateName
      } else if (input == 'creditCard') {
        this.validateFranquiciaCard()
      } else if (input == 'tipoDoc') {
        this.invalidTipoDoc = !this.tipoDoc
      } else if (input == 'doc') {
        this.invalidDoc = !this.validateDoc
      } else if (input == 'month') {
        this.invalidMonth = !this.month
      } else if (input == 'year') {
        this.invalidYear = !this.year
      } else if (input == 'ccv') {
        let re = /[0-9]{3,4}/
        this.invalidCcv = !re.test(this.ccv)
      } else if (input == 'ncuotas') {
        this.invalidNcuotas = !this.nCuotas
      }
    },
    async sendData() {
      await this.validateFranquiciaCard()
      this.invalidName = !this.validateName
      this.invalidTipoDoc = !this.tipoDoc
      this.invalidDoc = !this.validateDoc
      this.invalidMonth = !this.month
      this.invalidYear = !this.year
      this.invalidCcv = !(this.ccv.length >= 3 || this.ccv.length <= 4)
      this.invalidNcuotas = !this.nCuotas
      if (
        !this.invalidName &&
        !this.invalidNCreditCard &&
        !this.invalidTipoDoc &&
        !this.invalidDoc &&
        !this.invalidMonth &&
        !this.invalidYear &&
        !this.invalidCcv &&
        !this.invalidNcuotas
      ) {
        await this.sendPaymentCreditCard()
        if (!this.msgerrorPayment) {
          GA4AddPayment('Tarjeta crédito / débito')
          GA4Purchase()
        }
      }
    },
    async validateFranquiciaCard() {
      let data = new FormData()
      data.append('creditCardNumber', this.nCreditCard)
      const reCredit = /^\d+$/
      if (reCredit.test(this.nCreditCard)) {
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/payu/info_tarjeta_credito',
          data: data,
        }).then(response => {
          if (response.data) {
            this.typeCard = response.data.scheme
            this.invalidNCreditCard = false
          } else {
            this.invalidNCreditCard = true
            this.typeCard = ''
          }
        })
      } else {
        this.invalidNCreditCard = true
        this.typeCard = ''
      }
    },
    async sendPaymentCreditCard() {
      let data = new FormData()
      this.$store.state.loaderClover = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      data.append(
        'token',
        this.$route.params.tokenCart
          ? this.$route.params.tokenCart
          : window.localStorage.getItem('tokenCart'),
      )
      data.append('paymentMethodID', this.typeCard)
      data.append('securityCode', this.ccv)
      data.append('cardExpirationYear', this.year)
      data.append('cardholderName', this.name)
      data.append('docNumber', this.doc)
      data.append('cardNumber', this.nCreditCard)
      data.append('cardExpirationMonth', this.month)
      data.append('docType', this.tipoDoc)
      data.append('installments', this.nCuotas)
      data.append('pedido_fe_dian', this.esFE)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/payu/cargar_tarjeta_credito',
        data: data,
      })
        .then(response => {
          sendFBQEvent(this, 'Purchase', {
            value: response.data.response.pedido.carrito.carrito_total,
            currency: 'COP',
            contents: response.data.response.productos_purchase,
            content_ids: response.data.response.productos_purchase.map(
              e => e.id,
            ),
            event_id:
              response.data.response.pedido.pedido.pedido_reference_sale,
            content_type: 'product',
          })
          // CL-CheckoutPayment
          window.dataLayer.push({
            event: 'CL-CheckoutPayment',
            step: 4,
            carrito_id: this.dataCart.carrito_id,
            ecommerce: {
              currencyCode: 'COP',
              products: this.getDataAnalytics,
              subtotal: this.dataCart.carrito_subtotal,
              total: this.dataCart.carrito_total,
            },
            profile: {
              email: this.dataCart.cliente_correo,
              tipo_doc: this.dataCart.tipodoc,
              documento: this.dataCart.cliente_documento,
              nombre: this.dataCart.cliente_nombre,
              apellidos: this.dataCart.cliente_apellidos,
              celular: this.dataCart.cliente_celular,
            },
            shipping: {
              direccion: this.dataCart.cliente_direccion,
              barrio: this.dataCart.cliente_barrio,
              departamento_id: this.dataCart.cliente_departamento,
              ciudad_id: this.dataCart.cliente_ciudad,
              observaciones: this.dataCart.observaciones,
            },
            payment: {
              total: this.dataCart.carrito_total,
              paymentMethod: 'Tarjeta de crédito',
              coupon: this.dataCart.cupon_codigo,
            },
            value: this.dataCart.carrito_total,
          })
          // this.$gtag.event('add_payment_info', {
          //   coupon: this.dataCart.cupon,
          //   currency: 'COP',
          //   items: this.cart,
          //   paymentType: 'Tarjeta de credito',
          //   value: this.dataCart.carrito_subtotal,
          // })
          this.$store.state.cart.dataSummaryPayment = response.data.response
          this.$store.state.loaderClover = false
        })
        .catch(e => {
          this.errorPayment = true
          this.$store.state.loaderClover = false
          if (e.response.status == 423) {
            // this.$store.state.dataModal423.descripcion =
            //   e.response.data.errors.carrito[0]
            // this.$store.state.dataModal423.routeName =
            //   e.response.data.redireccionar_catalogo &&
            //   e.response.data.redireccionar_catalogo == 1
            //     ? 'Store'
            //     : 'PaymentEmail'
            // this.$store.state.showModal423 = true
            this.$bvModal.show('modal-products-sold-out')
          } else if (e.response.status == 500) {
            this.msgerrorPaymentCupo =
              'Parece que algo salió mal, por favor intenta más tarde.'
          } else if (e.response.status == 406) {
            this.$store.state.cart.msgModalExcepcion406 =
              e.response.data.errors.bono_regalo[0]
            this.$store.state.cart.btnModalExcepcion406 = 'cupo'
          } else if (e.response.data.response.message) {
            this.msgerrorPayment = e.response.data.response.message
          } else if (e.response.data.response.errors) {
            let message = ''
            for (const property in e.response.data.response.errors) {
              message =
                message + e.response.data.response.errors[property] + ' '
            }
            this.msgerrorPayment = message
          } else {
            this.msgerrorPayment = e.response.data.response.msg
          }
        })
    },
  },
}
</script>
