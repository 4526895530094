<template>
  <div class="text-center">
    <p class="mb-4">
      Usa tu cuenta de ahorros o corriente, recuerda que debes tener activo el
      servicio de clave dinámica de la App Bancolombia. Asegúrate de tener
      <b>
        ${{ new Intl.NumberFormat('de-DE').format(dataCart.carrito_total) }}
      </b>
      para finalizar tu compra.
    </p>
    <button
      class="bnt-primary col-12 col-lg-10 col-xl-5 mx-auto"
      @click="payBancolombia()"
    >
      Pagar con Bancolombia
    </button>
    <div v-if="errorPaymentBancolombia" class="text-center my-3">
      <span class="text-error">{{ errorPaymentBancolombia }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      errorPaymentBancolombia: '',
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla.name,
          brand: product.marca,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  methods: {
    async payBancolombia() {
      this.$store.state.loaderClover = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.errorPaymentBancolombia = ''
      let data = new FormData()
      data.append('token', window.localStorage.getItem('tokenCart'))
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/bancolombia/compra',
        data: data,
      })
        .then(response => {
          GA4AddPayment('Bancolombia')
          GA4Purchase()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderClover = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.msgModalExcepcion406 = err.response.data.errors.bono_regalo[0]
            this.btnModalExcepcion406 = 'pse'
          } else {
            this.errorPaymentBancolombia = err.response.data.response.msg
          }
        })
    },
  },
}
</script>
