<template>
  <div class="conatiner-checkout-summary">
    <data-summary-payment
      :data-response="dataSummaryPayment.pedido"
      :products="dataSummaryPayment.pedido.productos"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/checkout/DataSummaryPayment.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: { DataSummaryPayment },
  data() {
    return {
      range: null,
      mensaje: '',
      sendedPoll: false,
      errorEncuesta: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'dataSummaryPayment',
      'errorSurvey',
      'msgErrorSurvey',
    ]),
    valueRatingMovil() {
      return this.styleRange[this.range]
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.dataSummaryPayment.pedido.productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_descripcion,
          size: product.talla,
          brand: product.etiquetas_compra,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  created() {
    let carrito = this.dataSummaryPayment.pedido.carrito
    // CL-Purchase
    window.dataLayer.push({
      event: 'CL-Purchase',
      carrito_id: carrito.carrito_id,
      step: 5,
      ecommerce: {
        currencyCode: 'COP',
        products: this.getDataAnalytics,
        subtotal: carrito.carrito_subtotal,
        total: carrito.carrito_total,
      },
      profile: {
        email: carrito.cliente_correo,
        tipo_doc: carrito.tipodoc,
        documento: carrito.cliente_documento,
        nombre: carrito.cliente_nombre,
        apellidos: carrito.cliente_apellidos,
        celular: carrito.cliente_celular,
      },
      shipping: {
        direccion: carrito.cliente_direccion,
        barrio: carrito.cliente_barrio,
        departamento_id: carrito.cliente_departamento,
        ciudad_id: carrito.cliente_ciudad,
        observaciones: carrito.observaciones,
      },
      payment: {
        total: carrito.carrito_total,
        paymentMethod: carrito.carrito_medio_pago,
        coupon: carrito.cupon_codigo,
      },
      value: carrito.carrito_total,
    })
    window.scrollTo(0, 0)
    // this.$gtag.event('purchase', {
    //   affiliation: 'Derek',
    //   coupon: this.dataSummaryPayment.pedido.carrito.cupon_codigo,
    //   currency: 'COP',
    //   items: this.dataSummaryPayment.productos,
    //   transaction_id: this.dataSummaryPayment.pedido.carrito.carrito_id,
    //   shipping: this.dataSummaryPayment.pedido.carrito.carrito_envio,
    //   value: this.dataSummaryPayment.pedido.carrito.carrito_subtotal,
    // })
    window.sendinblue.identify(
      this.dataSummaryPayment.pedido.carrito.cliente_correo,
    )
    window.sendinblue.track(
      'purchase_derek',
      {
        email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
      },
      {
        id: this.dataSummaryPayment.pedido.pedido_id,
        data: {
          revenue: this.dataSummaryPayment.carrito.carrito_total,
          venta_cruzada: this.dataSummaryPayment.dataTrackPurchaseSendinblue
            .venta_cruzada,
          venta_genero: this.dataSummaryPayment.dataTrackPurchaseSendinblue
            .genero_id,
          items: this.dataSummaryPayment.dataTrackPurchaseSendinblue
            .items_carrito,
          items_complementarios: this.dataSummaryPayment
            .dataTrackPurchaseSendinblue.items_complementarios,
        },
      },
    )
  },
  beforeDestroy() {
    window.localStorage.removeItem('tokenCart')
    this.$store.state.cart.dataSummaryPayment = null
    this.$store.state.cart.dataCart = null
    this.$store.state.cart.productsCart = null
  },
  methods: {
    ...mapActions('cart', ['RegisterPollCheckout']),
    async registerPoll() {
      this.errorEncuesta = false
      if (this.range) {
        await this.RegisterPollCheckout({
          pedido_id: this.dataSummaryPayment.pedido.pedido
            .pedido_reference_sale,
          puntaje: this.range,
          mensaje: this.mensaje,
        }).then(() => {
          if (!this.errorSurvey) {
            this.sendedPoll = true
          }
        })
      } else {
        this.errorEncuesta = true
      }
    },
  },
}
</script>
