<template>
  <div class="payment-contra-entrega">
    <p>
      Paga contra entrega en efectivo, ten la cantidad de dinero exacta de tu
      pedido ya que el transportador no tiene cambio, no olvides que es
      importante evitar al máximo el contacto cuando te entreguen tus productos.
      No realizamos entregas los domingos y días festivos.
    </p>
    <div class="col-12 col-md-6 ms-auto">
      <button class="bnt-primary w-100" @click="payContraEntrega()">
        Finalizar compra
      </button>
    </div>
    <div v-if="errorPayment">
      <span class="text-error">{{ msgerrorPaymentCupo }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      errorPayment: false,
      msgerrorPaymentCupo: '',
    }
  },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    ...mapState(['esFE']),
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          size: product.referencia.talla.name,
          brand: product.marca,
          id: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  methods: {
    async payContraEntrega() {
      let data = new FormData()
      this.$store.state.loaderClover = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      data.append(
        'token',
        this.$route.params.tokenCart
          ? this.$route.params.tokenCart
          : window.localStorage.getItem('tokenCart'),
      )
      data.append('pedido_fe_dian', this.esFE)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/pedidos/pago/contraentrega',
        data: data,
      })
        .then(response => {
          GA4AddPayment('Contra entrega')
          GA4Purchase()
          sendFBQEvent(this, 'Purchase', {
            value: response.data.response.pedido.carrito.carrito_total,
            currency: 'COP',
            contents: response.data.response.productos_purchase,
            content_ids: response.data.response.productos_purchase.map(
              e => e.id,
            ),
            event_id:
              response.data.response.pedido.pedido.pedido_reference_sale,
            content_type: 'product',
          })
          // CL-CheckoutPayment
          window.dataLayer.push({
            event: 'CL-CheckoutPayment',
            step: 4,
            carrito_id: this.dataCart.carrito_id,
            ecommerce: {
              currencyCode: 'COP',
              products: this.getDataAnalytics,
              subtotal: this.dataCart.carrito_subtotal,
              total: this.dataCart.carrito_total,
            },
            profile: {
              email: this.dataCart.cliente_correo,
              tipo_doc: this.dataCart.tipodoc,
              documento: this.dataCart.cliente_documento,
              nombre: this.dataCart.cliente_nombre,
              apellidos: this.dataCart.cliente_apellidos,
              celular: this.dataCart.cliente_celular,
            },
            shipping: {
              direccion: this.dataCart.cliente_direccion,
              barrio: this.dataCart.cliente_barrio,
              departamento_id: this.dataCart.cliente_departamento,
              ciudad_id: this.dataCart.cliente_ciudad,
              observaciones: this.dataCart.observaciones,
            },
            payment: {
              total: this.dataCart.carrito_total,
              paymentMethod: 'Contra entrega',
              coupon: this.dataCart.cupon_codigo,
            },
            value: this.dataCart.carrito_total,
          })
          this.$store.state.loaderClover = false
          this.$store.state.cart.dataSummaryPayment = response.data.response
        })
        .catch(e => {
          this.errorPayment = true
          this.$store.state.loaderClover = false
          if (e.response.status == 423) {
            // this.$store.state.dataModal423.descripcion =
            //   e.response.data.errors.carrito[0]
            // this.$store.state.dataModal423.routeName =
            //   e.response.data.redireccionar_catalogo &&
            //   e.response.data.redireccionar_catalogo == 1
            //     ? 'Store'
            //     : 'PaymentEmail'
            // this.$store.state.showModal423 = true
            this.$bvModal.show('modal-products-sold-out')
          } else if (e.response.status == 500) {
            this.msgerrorPaymentCupo =
              'Parece que algo salió mal, por favor intenta más tarde.'
          } else if (e.response.status == 406) {
            this.$store.state.cart.msgModalExcepcion406 =
              e.response.data.errors.bono_regalo[0]
            this.$store.state.cart.btnModalExcepcion406 = 'cupo'
          } else if (e.response.data.message) {
            this.msgerrorPaymentCupo = e.response.data.message
          } else {
            let message = ''
            for (const property in e.response.data.errors) {
              message = message + e.response.data.errors[property] + ' '
            }
            this.msgerrorPaymentCupo = message
          }
        })
    },
  },
}
</script>
